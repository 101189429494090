import {
  Alert,
  ModalContent,
  ModalContentBody,
  ModalContentFooter,
  ModalContentHeader,
  Spacer,
  Text,
} from "@zapier/design-system";
import { Modal } from "components/Modal";
import { Button } from "components/Button";
import { Suspense, useCallback, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Project, trpc } from "utils/trpc";
import { ZapRemovalOption } from "server/schemas/zaps";
import { Spinner } from "./Spinner";
import { ZapDeletePrompt } from "./ZapDeletePrompt";
import { styled } from "lib/theme";
import { useQueryClient } from "@tanstack/react-query";
import { deleteProjectFromListListingPageQueryData } from "../lib/query-cache-helpers/deleteProjectFromListListingPageQueryData";

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

type FormData = {
  relatedZapsAction: ZapRemovalOption;
};

function ModalDeleteProjectsBody(props: {
  projectIds: Project["id"][];
  onClosed: () => void;
}) {
  const queryClient = useQueryClient();
  const utils = trpc.useContext();
  const { data: zaps } = trpc.zaps.search.useQuery({
    projectIds: props.projectIds,
  });

  const deleteProject = trpc.projects.delete.useMutation({
    onSuccess(data) {
      void utils.projects.count.invalidate();
      deleteProjectFromListListingPageQueryData(queryClient, data.projectIds);
    },
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = useForm<FormData>({
    defaultValues: { relatedZapsAction: "disable-zap" },
  });

  const isDeletingZap = watch("relatedZapsAction") === "delete-zap";

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    await deleteProject.mutateAsync({
      projectIds: props.projectIds,
      relatedZapsDeleteOption: data.relatedZapsAction,
    });
    props.onClosed();
  };

  return (
    <>
      <ModalContentHeader>
        <Text type="subHeader3Bold">Delete Interface</Text>
      </ModalContentHeader>
      <ModalContentBody scrollable>
        <form>
          <Text tag="p" type="paragraph3" color="neutral700">
            Are you sure you want to delete your{" "}
            <b>{props.projectIds.length}</b> Interface(s)? This action cannot be
            undone.
          </Text>
          {zaps && zaps?.length > 0 ? (
            <>
              <Spacer height={15} />
              <Controller
                name="relatedZapsAction"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <>
                    <ZapDeletePrompt
                      prompt={`What should happen to the Zaps connected to these Interfaces?`}
                      value={field.value}
                      onChange={(value) => field.onChange(value)}
                    />

                    <Spacer height={20} />
                    {field.value === "delete-zap" && (
                      <Alert type="error">
                        {zaps.length} zap{zaps.length > 1 && "s"} will be
                        deleted
                      </Alert>
                    )}
                  </>
                )}
              />
            </>
          ) : null}
        </form>
      </ModalContentBody>
      <ModalContentFooter>
        <ButtonWrapper>
          <Button
            type="submit"
            color="danger"
            isLoading={isSubmitting}
            onClick={() => handleSubmit(onSubmit)()}
          >
            Delete Interface{props.projectIds.length > 1 && "s"}{" "}
            {isDeletingZap ? "& Zaps" : null}
          </Button>
          <Button type="button" color="tertiary" onClick={props.onClosed}>
            Cancel
          </Button>
        </ButtonWrapper>
      </ModalContentFooter>
    </>
  );
}

export function ModalBulkDeleteProjects(props: {
  projectIds: Project["id"][];
  onClosed: () => void;
}) {
  return (
    <Modal onClosed={props.onClosed} ariaLabel="Delete Interface">
      <ModalContent maxWidth="60ch">
        <Suspense fallback={<Spinner height={200} />}>
          <ModalDeleteProjectsBody {...props} />
        </Suspense>
      </ModalContent>
    </Modal>
  );
}

export function useModalBulkDeleteProjects(
  projectIds: Project["id"][],
  onClosed?: () => void
) {
  const [isOpen, setIsOpen] = useState(false);

  const confirmDelete = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const renderModal = useCallback(() => {
    if (isOpen && projectIds.length > 0) {
      return (
        <ModalBulkDeleteProjects
          projectIds={projectIds}
          onClosed={() => {
            setIsOpen(false);
            onClosed?.();
          }}
        />
      );
    }

    return null;
  }, [isOpen, projectIds, onClosed]);

  return [confirmDelete, renderModal] as const;
}
