import { useEffect, useState } from "react";
import { useUser } from "lib/context/user-context";
import { config } from "server/config";
import { useAccountPlan } from "lib/hooks/useAccountPlan";
import { startCase } from "lodash";

interface AccountData {
  plan_status: {
    plan_type: string;
  };
  limits: {
    action_limit: number;
  };
  signup: string;
}

interface WootricParams {
  projectCount?: number;
}

const Wootric = ({ projectCount = 0 }: WootricParams) => {
  const { user } = useUser();
  const { planName } = useAccountPlan(user);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const [wootricHasInitialized, setWootricHasInitialized] = useState(false);

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const language = Intl.DateTimeFormat().resolvedOptions().locale;

  useEffect(() => {
    if (!wootricHasInitialized && !isMobile) {
      const getAccount = async () => {
        const response = await fetch(
          `${config().NEXT_PUBLIC_ZAPIER_API_BASE_URL}/api/v4/accounts/${
            user?.currentAccount
          }/entitlements/`,
          { mode: "cors", credentials: "include" }
        ).then((r) => r.json());

        return response;
      };

      void getAccount().then((accountData: AccountData) => {
        if (document && document.getElementById("wootric-settings") === null) {
          const setupScript = document.createElement("script");
          setupScript.type = "text/javascript";
          setupScript.id = "wootric-settings";
          setupScript.async = true;

          // NEXT_PUBLIC_WOOTRIC_ACCOUNT_TOKEN is safe to be saved in .env files since
          // it's interpolated into the script tag on the client side anyway, so it will always be exposed to any user who inspects the page
          setupScript.innerHTML = `
      window.wootricSettings = {
        account_token: "NPS-edfb676e",
        aria: true,
        created_at: ${Math.floor(
          new Date(user?.createdAt || accountData?.signup).getTime() / 1000
        )},
        email: "${user?.email}",
        modal_footprint: "compact",
        properties: {
          date_user_created: "${
            accountData?.signup
              ? Math.floor(new Date(accountData?.signup).getTime() / 1000)
              : null
          }",
          account_id: "${user?.currentAccount}",
          customuser_id: "${user?.zapierId}",
          email: "${user?.email}",
          language: "${language}",
          plan_tier: "${accountData?.plan_status?.plan_type}${
            accountData?.limits?.action_limit
              ? ` (${accountData?.limits?.action_limit?.toLocaleString()} Tasks)`
              : ""
          }",
          interfaces_plan_tier: "${startCase(planName)}",
          timezone: "${timezone}",
          interfaces_amount: ${projectCount}
        },
      };
    `;
          document.body.appendChild(setupScript);
        }

        // Beacon
        if (document && document.getElementById("wootric-beacon") === null) {
          const beacon = document.createElement("script");
          beacon.type = "text/javascript";
          beacon.id = "wootric-beacon";
          beacon.async = true;

          beacon.src = "https://cdn.wootric.com/wootric-sdk.js";
          beacon.onload = function () {
            // @ts-ignore
            window.wootric("run");
          };

          document.body.appendChild(beacon);
        }

        setWootricHasInitialized(true);
      });
    }
  }, [
    planName,
    isMobile,
    language,
    projectCount,
    timezone,
    user?.createdAt,
    user?.currentAccount,
    user?.email,
    user?.zapierId,
    wootricHasInitialized,
  ]);

  if (isMobile) return null;

  return <div />;
};

export default Wootric;
